.containerModal {
  max-width: 90%;
  margin: 0 auto;
}
.allDayChecked {
  background-color: white;
  padding: 1rem;
  border-radius: 20px;
  border-color: #2980b9;
  border-style: solid;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.allDayUnchecked {
  background-color: white;
  padding: 1rem;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.specific {
  visibility: hidden;
  position: fixed;
  cursor: pointer;
}

.distribute {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
}

.inputSpacement {
  display: flex;
  width: 45%;
  flex-direction: column;
}

.uraSelection {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 80%;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.urasField {
  height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkInternal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2px;
}

.containerTime {
  max-width: 50%;
}
